import { icon } from "leaflet";
import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Command size={15} />,
    // badge: "warning",
    // badgeText: "2",
    parentof: "dashboard",
    // children: [
    //   {
    //     id: "statistics",
    //     title: "Statistics",
    //     type: "item",
    //     icon: <Icon.Minus size={10} />,
    //     permissions: ["admin", "editor"],
    //     parentof: "dashboard",
    //     navLink: "/cards/statistics",
    //   },
    // ],
  },

  {
    id: "user",
    title: "User",
    type: "collapse",
    icon: <Icon.User size={15} />,
    parentof: "dashboard",
    children: [
      {
        id: "pendingkyc",
        title: "Pending KYC",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "users",
        navLink: "/app/user/pendinguser",
      },
      {
        id: "verifiedkyc",
        title: "Verified KYC",
        type: "item",
        parentof: "users",
        icon: <Icon.Minus size={10} />,
        navLink: "/app/user/verifieduser",
      },
      {
        id: "userrejectedkyc",
        parentof: "users",
        title: "User Rejected KYC",
        type: "item",
        icon: <Icon.Minus size={10} />,

        navLink: "/app/user/rejecteduser",
      },
      {
        id: "alluser",
        parentof: "users",
        title: "All Users",
        type: "item",
        icon: <Icon.Minus size={10} />,

        navLink: "/app/user/alluser",
      },
      {
        id: "blockuser",
        title: "Blocked Users",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "users",
        navLink: "/app/user/blockuser",
      },
      // {
      //   id: "topreffred",
      //   title: "Top Reffered",
      //   type: "item",
      //   icon: <Icon.CheckSquare size={15} />,
      //
      //   filterBase: "/app/user/bankuser?action=blockuser&raw=0"
      // },
      {
        id: "userbankkyc",
        title: "Bank Verified KYC",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "users",
        navLink: "/app/user/bankverified",
      },
      {
        id: "bankrejectedkyc",
        title: "Bank Rejected KYC",
        type: "item",
        parentof: "users",
        icon: <Icon.Minus size={10} />,

        navLink: "/app/user/bankrejected",
      },
      {
        id: "bankpendingkyc",
        title: "Bank Pending KYC",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "users",
        navLink: "/app/user/bankpending",
      },
    ],
  },

  {
    id: "p2preport",
    type: "collapse",
    title: "P2P Report",
    icon: <Icon.Activity size={15} />,
    parentof: "p2preport",

    children: [
      {
        id: "pendingp2porder",
        title: "Pending P2P Order",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "p2preport",
        navLink: "/app/p2p/pendingp2porder",
      },
      {
        id: "verifiedp2porder",
        title: "P2P Order",
        type: "item",
        parentof: "p2preport",
        icon: <Icon.Minus size={10} />,
        navLink: "/app/p2p/verifiedp2porder",
      },
      {
        id: "openbuyp2porder",
        title: "Buy P2P Order",
        type: "item",
        parentof: "p2preport",
        icon: <Icon.Minus size={10} />,
        navLink: "/app/p2p/openbuyp2porder",
      },
      {
        id: "opensellp2porder",
        title: "Sell P2P Order",
        type: "item",
        parentof: "p2preport",
        icon: <Icon.Minus size={10} />,
        navLink: "/app/p2p/opensellp2porder",
      },
    ],
  },
  {

    id:"withdrawrequest",
    type: "collapse",
    title: "Withdraw Request",
    icon: <Icon.Package size={15} />,
    parentof: 'withdrawrequest',
    children: [
  {
    id: "pendingINRwithdraw",
    title: "INR Pending Withdraw",
    type: "item",
    icon: <Icon.Minus size={12} />,
    parentof: 'withdrawrequest',
    navLink: "/app/withdraw/pendingINRwithdraw",
  },
  {
    id: "pendingwithdraw",
    title: "Coin Pending Withdraw",
    type: "item",
    icon: <Icon.Minus size={12} />,
    parentof: 'withdrawrequest',
    navLink: "/app/withdraw/pendingwithdraw",
  },
  // {
  //   id: "verifiedwithdraw",
  //   title: "Verified Withdraw",
  //   type: "item",
  //   parentof: 'withdrawrequest',
  //   icon: <Icon.Minus size={12} />,
  //   navLink: "/app/withdraw/verifiedwithdraw",
  // },
  // {
  //   id: "rejectedwithdraw",
  //   parentof: 'withdrawrequest',
  //   title: "Rejected Withdraw ",
  //   type: "item",
  //   icon: <Icon.Minus size={12} />,

  //   navLink: "/app/withdraw/rejectedwithdraw"
  // },
]
},
  {
    id: "coinandtoken",
    title: "Coin Token",
    type: "collapse",
    icon: <Icon.Package size={15} />,
    parentof: "coinandtoken",
    children: [
      {
        id: "addtoken",
        title: "Add token",
        type: "item",
        parentof: "coinandtoken",
        icon: <Icon.Minus size={10} />,
        badge: "primary",

        navLink: "/app/token/addtoken",
      },
      {
        id: "crptosetting",
        title: "Cypto Setting",
        type: "item",
        parentof: "coinandtoken",
        icon: <Icon.Minus size={10} />,

        navLink: "/app/token/crptosetting",
      },
      {
        id: "crptosetting1",
        title: "deposit/withdraw Setting",
        type: "item",
        parentof: "coinandtoken",
        icon: <Icon.Minus size={10} />,

        navLink: "/app/token/depositwithdraw",
      },
      {
        id: "symboldetail",
        title: "Symbol Balance Detail",
        type: "item",
        parentof: "coinandtoken",
        icon: <Icon.Minus size={10} />,

        navLink: "/app/token/symboldetail",
      },
      {
        id: "crptolimit",
        title: "Cypto Limit/Capping",
        type: "item",
        parentof: "coinandtoken",
        icon: <Icon.Minus size={10} />,

        navLink: "/app/token/crptolimit",
      },
      {
        id: "createpairing",
        title: "Create Pairing",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "coinandtoken",
        navLink: "/app/token/createpairing",
      },
      {
        id: "setpairing",
        title: "Set Pairing",
        type: "item",
        parentof: "coinandtoken",
        icon: <Icon.Minus size={10} />,

        navLink: "/app/token/setpairing",
      },
    ],
  },

  {
    id: "wallet",
    type: "collapse",
    title: "Wallet Settings",
    parentof: "wallet",
    icon: <Icon.Briefcase size={15} />,
    children: [
      {
        id: "coldwallet",
        title: "Cold Wallet",
        type: "item",
        parentof: "wallet",
        icon: <Icon.Minus size={10} />,

        navLink: "/app/wallets/coldwallet",
      },
      {
        id: "capturehits",
        title: "Capture History",
        type: "item",
        parentof: "wallet",
        icon: <Icon.Minus size={10} />,
        badge: "primary",
        navLink: "/app/wallets/capturehits",
      },
      {
        id: "adminsendcapture",
        title: "Admin Send Capture",
        type: "item",
        parentof: "wallet",
        icon: <Icon.Minus size={10} />,
        badge: "primary",
        navLink: "/app/wallets/adminsendcapture",
      },
      {
        id: "capturefundhistory",
        title: "User Capture History",
        type: "item",
        parentof: "wallet",
        icon: <Icon.Minus size={10} />,
        badge: "primary",
        navLink: "/app/wallets/capturefundhistory",
      },

      {
        id: "hotwallet",
        title: "Hot Wallet",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "wallet",
        navLink: "/app/wallets/hotwallet",
      },
      {
        id: "tradefeeswallet",
        title: "Trade Fees",
        type: "item",
        parentof: "wallet",
        icon: <Icon.Minus size={10} />,
        navLink: "/app/wallets/tradefeeswallet",
      },
      {
        id: "withdrawfeeswallet",
        title: "With/Dep Fees",
        type: "item",
        parentof: "wallet",
        icon: <Icon.Minus size={10} />,
        navLink: "/app/wallets/withdrawfeeswallet",
      },
    ],
  },
  {
    id: "order",
    type: "collapse",
    title: "Order Report",
    icon: <Icon.PieChart size={15} />,
    parentof: "order",
    children: [
      {
        id: "openbuy",
        title: "Open Buy",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "order",
        navLink: "/app/order/openbuy",
      },
      {
        id: "opensell",
        title: "Open Sell",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "order",
        navLink: "/app/order/opensell",
      },
      {
        id: "excutedbuysell",
        title: "Executed Order",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "order",
        navLink: "/app/order/excutedbuysell",
      },
      {
        id: "canclebuyorder",
        title: "Cancled Buy Orders",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "order",
        navLink: "/app/order/canclebuyorder",
      },
      {
        id: "canclesellorder",
        title: "Cancled Sell Orders",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "order",
        navLink: "/app/order/canclesellorder",
      },
      {
        id: "openfutureorder",
        title: "Open Future Order",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "order",
        navLink: "/app/order/openfutureorder",
      },
      {
        id: "positionfutureorder",
        title: "Position Future Order",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "order",
        navLink: "/app/order/positionfutureorder",
      },
      {
        id: "futureorderhistory",
        title: "Future Order History",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "order",
        navLink: "/app/order/futureorderhistory",
      },
      // {
      //   id: "createorder",
      //   title: "Create Order",
      //   type: "item",
      //   icon: <Icon.Minus size={10} />,
      //   parentof: "order",
      //   navLink: "/app/order/createorder",
      // },
    ],
  },
  {
    id: "finance",
    type: "collapse",
    title: "Finance Report",
    parentof: "finance",
    icon: <Icon.DivideCircle size={15} />,
    children: [
      {
        id: "usertradefund",
        title: "User Trade Fund",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "finance",
        navLink: "/app/finance/usertradefund",
      },
      {
        id: "userwalletfund",
        title: "User Wallet Fund",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "finance",
        navLink: "/app/finance/userwalletfund",
      },
      {
        id: "deposithistory",
        title: "INR Deposite History",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "finance",
        navLink: "/app/finance/deposithistory",
      },
      {
        id: "depositcoinhistory",
        title: "Coin Deposite History",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "finance",
        navLink: "/app/finance/depositcoinhistory",
      },
      {
        id: "withdrawistory",
        title: "Coin Withdraw History",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "finance",
        navLink: "/app/finance/withdrawhistory",
      },
      // {
      //   id: "withdrawistory",
      //   title: "INR Withdraw History",
      //   type: "item",
      // icon: <Icon.Minus size={10} />,
      //   parentof: 'finance',
      //   navLink: "/app/finance/withdrawhistoryinr",
      // },
      {
        id: "withdrawpdf",
        title: "INR Withdraw History",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "finance",
        navLink: "/app/finance/withdrawpdfinr",
      },
      {
        id: "userreport",
        title: "User Report",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "finance",
        navLink: "/app/finance/userreport",
      },
      // {
      //   id: "userdeposithistory",
      //   title: "User Deposite History",
      //   type: "item",
      // icon: <Icon.Minus size={10} />,
      //   parentof: 'finance',
      //   navLink: "/app/finance/userdeposithistory",
      // },
      // {
      //   id: "userwithdrawistory",
      //   title: "User Withdraw History",
      //   type: "item",
      // icon: <Icon.Minus size={10} />,
      //   parentof: 'finance',
      //   navLink: "/app/finance/userwithdrawistory",
      // },
      {
        id: "adduserfund",
        title: "Add Fund to User",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "finance",
        navLink: "/app/finance/adduserfund",
      },
      // {
      //   id: "depositinrfromuser",
      //   title: "Deposit Inr From User",
      //   type: "item",
      //  icon: <Icon.Minus size={10} />,
      //   parentof: 'finance',
      //   navLink: "/app/finance/depositinrfromuser",
      // },
      // {
      //   id: "withdrawinrfromuser",
      //   title: "Withdraw Inr From User",
      //   type: "item",
      //  icon: <Icon.Minus size={10} />,
      //   parentof: 'finance',
      //   navLink: "/app/finance/withdrawinrfromuser"
      // },
      {
        id: "capturefundfromuser",
        title: "INR Deposit Pending",
        type: "item",
        icon: <Icon.Minus size={10} />,
        parentof: "finance",
        navLink: "/app/finance/capturefundfromuser",
      },
      // {
      //   id: "realfundtranfertouser",
      //   title: "Real Fund User",
      //   type: "item",
      //   icon: <Icon.Info size={15} />,
      //   parentof: 'finance',
      //   navLink: "/app/finance/realfundtranfertouser",
      // },
      {
        id: "withdrawalfeesreport",
        title: "Withdrawal Fees List",
        type: "item",
        icon: <Icon.Minus size={10} />,
        navLink: "/app/finance/withdrawalfeesreport",
        parentof: "finance",
      },
      {
        id: "AdminCommissionReport",
        title: "Sell commission List",
        type: "item",
        icon: <Icon.Minus size={10} />,
        navLink: "/app/finance/admincommissionreport",
        parentof: "finance",
      },
      {
        id: "stakehistory",
        title: "Stake Report",
        icon: <Icon.Minus size={10} />,
        type: "item",
        parentof: "settings",
        navLink: "/app/finance/stakingreport",
      },
      // {
      //   id: "stakehistorydaily",
      //   title: "Daily Stake History",
      //   icon: <Icon.Info size={15} />,
      //   type: "item",
      //   parentof: 'finance',
      //   navLink: "/app/finance/stakehistorydaily"
      // },
    ],
  },
  {
    id: "bonus",
    type: "collapse",
    title: "Bonus (Report)",
    parentof: "bonus",
    icon: <Icon.Star size={15} />,
    children: [
      {
        id: "refferrellist",
        title: "Referral List",
        type: "item",
        parentof: "bonus",
        icon: <Icon.Minus size={10} />,
        badge: "primary",
        navLink: "/app/bonus/refferrellist",
        parentof: "bonus",
      },
      {
        id: "refferrellistnotkyc",
        title: "Referral (Not KYC)",
        type: "item",
        parentof: "bonus",
        icon: <Icon.Minus size={10} />,
        badge: "primary",
        navLink: "/app/bonus/refferrellistnotkyc",
        parentof: "bonus",
      },
      {
        id: "airdroplist",
        title: "Signup Reward List",
        type: "item",
        icon: <Icon.Minus size={10} />,
        navLink: "/app/bonus/airdroplist",
        parentof: "bonus",
      },
    ],
  },

  {
    id: "master",
    type: "collapse",
    title: "Master Setting",
    parentof: "master",
    icon: <Icon.Disc size={15} />,
    children: [
      {
        // id: "addemployee",
        title: "Add Employee",
        type: "item",
        parentof: "master",
        icon: <Icon.Minus size={10} />,
        navLink: "/app/master/addemployee",
        parentof: "master",
      },
      {
        // id: "mastersetting",
        title: "Personal Setting",
        type: "item",
        parentof: "master",
        icon: <Icon.Minus size={10} />,
        badge: "primary",
        navLink: "/app/master/mastersetting",
        parentof: "master",
      },
      {
        // id: "mastersetting",
        title: "Update Notification",
        type: "item",
        parentof: "master",
        icon: <Icon.Minus size={10} />,
        badge: "primary",
        navLink: "/app/master/notification",
        parentof: "master",
      },
    ],
  },

  {
    id: "settings",
    type: "collapse",
    title: "Setting",
    icon: <Icon.Settings size={15} />,
    parentof: "settings",
    children: [
      {
        // id: "logobanner",
        title: "Logo/Banner/Favicon",
        icon: <Icon.Minus size={10} />,
        type: "item",
        parentof: "settings",
        navLink: "/app/setting/logobanner",
      },
      // {
      //   // id: "addnewpage",
      //   title: "Add New Page",
      //   icon: <Icon.Minus size={10} />,
      //   type: "item",
      //   parentof: "settings",
      //   navLink: "/app/setting/addnewpage",
      // },
      // {
      //   // id: "addnewmenu",
      //   title: "Add New Menu",
      //   icon: <Icon.Minus size={10} />,
      //   type: "item",
      //   parentof: "settings",
      //   navLink: "/app/setting/addnewmenu",
      // },
      {
        // id: "apisetting",
        title: "API Setting",
        icon: <Icon.Minus size={10} />,
        type: "item",
        parentof: "settings",
        navLink: "/app/setting/apisetting",
      },
      {
        // id: "websetting",
        title: "Setting",
        icon: <Icon.Minus size={10} />,
        type: "item",
        parentof: "settings",
        navLink: "/app/setting/websetting",
      },
      {
        // id: "stakesetting",
        title: "Stake Setting",
        icon: <Icon.Minus size={10} />,
        type: "item",
        parentof: "settings",
        navLink: "/app/setting/stakesetting",
      },
      // {
      //   // id: "remotetrading",
      //   title: "Remote Trading",
      //   icon: <Icon.Minus size={10} />,
      //   type: "item",
      //   parentof: "settings",
      //   navLink: "/app/setting/remotetrading",
      // },
    ],
  },
];

export default navigationConfig;
